export const META_CONTENTS = Object.freeze({
  // Public Pages
  ['home']: {
    title: `Your Trusted Self Storage Partner`,
    description: `ShipAndStorage offers smart self storage solutions with worldwide shipping services via FedEx and DHL. Discover our range of units, convenient booking, and exceptional service!`,
    keywords: `smart storage, worldwide shipping , FedEx, DHL, storage services , shipping services , secure storage, storage solutions , international shipping `
  },
  ['how_ship_and_storage_works']: {
    title: `How Ship and Storage Works - A Simple Guide`,
    description: `Learn how Ship and Storage works with our simple step-by-step guide. Convenient shipping and storage options available for you today.`,
    keywords: `storage process, how it works, step-by-step storage`
  },
  ['college_storage_services']: {
    title: `Affordable College Storage Services for Students`,
    description: `Convenient and secure college storage services for students. Store your dorm items easily during breaks with flexible options.`,
    keywords: `college storage, student storage services, dorm storage, college storage solutions, summer storage for college, university storage services, affordable storage for students, college break storage`
  },
  ['drop_off_storage_locations']: {
    title: `Convenient Drop Off Storage Locations Near You`,
    description: `Find nearby drop-off storage locations to securely store your belongings. Flexible storage options available for short or long-term needs. Easy drop-off, secure storage!`,
    keywords: `drop off storage, storage locations near me, convenient storage drop off, secure storage locations, temporary storage, local storage options, drop off storage services`
  },
  ['pickup_and_storage']: {
    title: `Hassle-Free Pickup and Storage Services for Your Items`,
    description: `Enjoy easy pickup and storage services for your belongings. Our reliable team collects, stores, and returns your items whenever you need. Book today`,
    keywords: `pickup and storage, storage pickup service, hassle-free storage, easy storage options, storage with pickup, secure storage solutions, pickup and delivery storage`
  },
  ['how_storage_solutions_work']: {
    title: `How Storage Solutions Work – Simple & Secure Storage Guide`,
    description: `Discover how our storage solutions work! A step-by-step guide to safely store and retrieve your items. Flexible options for all your storage needs.`,
    keywords: `how storage solutions work, storage process, easy storage guide, storage solutions, secure storage services, flexible storage options, step-by-step storage`
  },
  ['domestic_shipping_services']: {
    title: `Reliable Domestic Shipping Services`,
    description: `Discover seamless domestic shipping services with competitive rates, quick delivery options, and advanced tracking. Ship luggage, and packages,  across the USA.`,
    keywords: `Domestic shipping services, affordable shipping, luggage shipping, package delivery, fast domestic shipping, USA shipping, reliable shipping solutions.`
  },
  ['international_shipping_services']: {
    title: `Easy International Shipping Solutions`,
    description: `Simplify international shipping with our reliable and cost-effective services. Ship luggage, packages, and specialty items to over 220 countries worldwide.`,
    keywords: `International shipping services, global shipping, luggage shipping worldwide, affordable international shipping, reliable shipping, customs clearance assistance, ship anywhere.`
  },
  ['shipping_to_storage']: {
    title: `Secure Shipping to Storage Services`,
    description: `Enjoy safe and flexible shipping to storage services with ShipAndStorage. Convenient, affordable, and secure options for your belongings.`,
    keywords: `shipping to storage, storage services, secure shipping, affordable storage, flexible storage options, ShipAndStorage`
  },
  ['luggage_shipping']: {
    title: `Affordable Luggage Shipping Worldwide`,
    description: `Ship your luggage easily with ShipAndStorage. Affordable rates, global delivery, and convenient door-to-door luggage shipping services.`,
    keywords: `luggage shipping, ship luggage, door-to-door luggage delivery, affordable luggage shipping, global luggage shipping, ShipAndStorage`
  },
  ['moving_box_shipping']: {
    title: `Reliable Moving Box Shipping Services`,
    description: `Simplify your move with ShipAndStorage. Affordable moving box shipping, storage options, and secure delivery to any destination.`,
    keywords: `moving box shipping, box shipping services, ship moving boxes, affordable box shipping, secure box delivery, ShipAndStorage`
  },
  ['snowboard_shipping']: {
    title: `Affordable Snowboard Shipping Services`,
    description: `Ship your snowboard hassle-free with ShipandStorage! Enjoy affordable rates, real-time tracking, and door-to-door delivery to top snowboarding destinations.`,
    keywords: `snowboard shipping, affordable snowboard shipping, ship snowboard internationally, snowboard storage services, door-to-door snowboard delivery, real-time snowboard tracking, winter sports equipment shipping, snowboard travel solutions, safe snowboard shipping, snowboard shipping company`
  },
  ['ski_gear_shipping']: {
    title: `Hassle-Free Ski Gear Shipping Worldwide`,
    description: `Ship your ski gear safely and affordably with ShipAndStorage. Enjoy door-to-door delivery, free insurance, real-time tracking, and flexible storage options.`,
    keywords: `ski gear shipping, ski equipment shipping, ship ski gear internationally, affordable ski gear shipping, door-to-door ski delivery, ski storage services, real-time ski tracking, winter sports gear shipping, safe ski gear transport, ski gear shipping company`
  },
  ['college_student_shipping']: {
    title: 'College Student Shipping Solutions Secure and Fast',
    description:
      'Secure and fast college student shipping services. Affordable solutions for shipping your belongings safely and on time to any destination.',
    keywords:
      'college student shipping, student shipping services, ship to dorm, student storage and shipping, affordable college shipping'
  },
  ['residential_storage']: {
    title: `Smart Residential Storage Solutions`,
    description: `ShipAndStorage offers smart residential storage solutions for various needs.  Find your perfect residential storage solution where convenience meets excellence.`,
    keywords: `Residential storage, emergency storage, smart storage, secure storage , renovation storage , worldwide shipping, convenient storage, storage solutions, valet storage, military deployment storage, household storage , affordable storage, temporary storage.`
  },
  ['business_storage_solution']: {
    title: `Your Premier Business Storage Solution`,
    description: `Find perfect business storage solution with SAS. Our storage units including warehouse & office space. Enjoy our affordable & flexible business storage today!`,
    keywords: `Storage units, commercial storage, retail storage, office document storage, pharmaceutical storage, storage solutions, trade show storage, business storage, secure storage, worldwide shipping , smart storage, inventory storage, e-commerce storage, document storage, affordable storage.`
  },
  ['moving_storage']: {
    title: `Hassle-Free Moving Storage Solutions `,
    description: `Discover convenient, flexible, and secure storage options with ShipAndStorage. Book now for your moving and storage needs!`,
    keywords: `Valet storage, door-to-door delivery, affordable storage, FedEx shipping, luggage storage, box storage, hassle-free storage, free storage, moving storage, secure storage, moving solutions, international shipping, storage solutions, simple pricing, transparent pricing, pack and ship, convenient storage.`
  },
  ['luggage_and_box_shipping_services']: {
    title: `Easy Luggage & Box Shipping Anywhere`,
    description: `ShipAndStorage simplifies luggage shipping. Order online and ship your luggage or boxes anywhere. Enjoy real-time tracking, free insurance, and 24/7 support.`,
    keywords: `Shipping tips, prohibited items real-time tracking, box shipping , labeling  free insurance , 24/7 support , delivery instructions , international shipping, convenient shipping, hotel pickup, shipment protection, secure shipping , luggage shipping.`
  },
  ['book_storage_facility']: {
    title: `Secure Self Storage Facility - Get A Quote`,
    description: `Get a quote for secure and convenient self storage facility solutions at ShipAndStorage..`,
    keywords: `self storage, storage facility, book now, get a quote, secure storage, convenient storage`
  },
  ['register']: {
    title: `Register with ShipAndStorage`,
    description: `Sign up for a ShipAndStorage account to enjoy our storage and shipping services.`,
    keywords: `register, sign up, create account, membership`
  },
  ['login']: {
    title: `Log in to our platform | Secure Access`,
    description: `Log in securely to your ShipAndStorage account to manage your storage and shipping needs. Visit shipandstorage.com/login.`,
    keywords: `login, secure access, account management, storage management, shipping `
  },
  ['change_password']: {
    title: `Change Password`,
    description: `Update your ShipAndStorage account password easily and securely on the Change Password page.`,
    keywords: `change password, update password, account security, password reset`
  },
  ['reset_password']: {
    title: `Reset Password`,
    description: `Easily reset your password for your ShipAndStorage account.`,
    keywords: `reset password, forgot password, account access`
  },
  ['order_packing_material']: {
    title: `Quality Packing Materials for Your Shipping Needs`,
    description: `Find a wide range of quality packing materials for your shipping needs at ShipAndStorage. From boxes to bubble wrap, we have you covered.`,
    keywords: `packing materials, shipping supplies, packaging materials, boxes, bubble wrap, tape, packing peanuts, shipping boxes, packing boxes, packaging supplies, shipping essentials, packing materials store`
  },
  ['about']: {
    title: `Smart Shipping & Storage Solution`,
    description: `Learn about ShipAndStorage's journey and commitment to providing convenient storage and shipping solutions.`,
    keywords: `about us, company history, our story, mission, values, storage solutions, shipping services, commitment, convenience, ShipAndStorage`
  },
  ['contact']: {
    title: `Get in Touch | Contact Information`,
    description: `Reach out to ShipAndStorage for any inquiries or assistance regarding storage and shipping services. Visit shipandstorage.com/contact-us.`,
    keywords: `contact us, get in touch, customer support, inquiries, assistance, storage services, shipping services, ShipAndStorage`
  },
  ['luggage_storage']: {
    title: `Innovative Luggage Storage & Shipping Solutions`,
    description: `Discover secure, affordable luggage storage and shipping solutions with ShipAndStorage. Flexible options for travelers, no size restrictions, and 24/7 support.`,
    keywords: `Luggage Storage, Box Storage, Ski Storage, Golf Club Storage, Snowboard Storage, Shipping & Storage Questions, Shipping Tips, What Can't Ship, Secure Storage, Affordable Luggage Storage, Flexible Storage Options, NYC Luggage Storage, Travel Storage Solutions, Convenient Luggage Storage, Luggage Storage Near Me, Smart Storage Solutions, ShipAndStorage, Luggage Shipping, Travel Tips, Vacation Storage, Hassle-Free Storage, Safe Luggage Storage, Luggage Storage NYC, Travel Convenience.`
  },
  ['box_storage']: {
    title: `Convenient Box Storage Solutions`,
    description: `Store your boxes securely with ShipAndStorage. Affordable rates, no size restrictions, and flexible pickup/drop-off options. Store without renting extra space.`,
    keywords: `box storage, storage solutions, secure storage, convenient storage, ShipAndStorage`
  },
  ['golf_club_storage']: {
    title: `Reliable Golf Club Storage Solutions`,
    description: `Keep your golf clubs secure with ShipAndStorage. Affordable, flexible storage options for golf enthusiasts. Perfect for travel and seasonal storage.`,
    keywords: `golf club storage, golf equipment storage solutions, golf club storage unit`
  },
  ['ski_storage']: {
    title: `Secure Ski Storage Solutions`,
    description: `Discover convenient and secure ski storage solutions at ShipAndStorage. Reserve your storage space today!`,
    keywords: `ski boot storage, ski gear storage, ski storage tips, snow ski storage, ski storage system, ski equipment storage`
  },
  ['snowboard_storage']: {
    title: `Secure and Affordable Snowboard Storage Solutions`,
    description: `Store your snowboard with ShipAndStorage. Affordable and flexible storage options for seasonal and travel needs. Safe and secure storage solutions.`,
    keywords: `Snowboard Storage, Secure Snowboard Storage, Seasonal Storage, Flexible Storage, Affordable Storage, Safe Snowboard Storage, Snowboard Equipment Storage, Storage Solutions, Convenient Snowboard Storage, Smart Storage Solutions, ShipAndStorage, Travel Storage, Winter Sports Storage, Snowboard Gear Storage, Snowboard Equipment, Easy Snowboard Storage, Storage Near Me, Storage Services, Safe Storage Options, Snowboarding Storage, Affordable Snowboard Storage, Secure Storage Facilities.`
  },
  ['luggage_box_storage']: {
    title: `Best Cheapest Storage Facilities Near Me`,
    description: `Luggage shipping services, Best luggage shipping services, International luggage shipping services, Luggage shipping service, Best international luggage shipping service, Cheapest luggage shipping service, Box shipping services, Box shipping services`,
    keywords: `Did you think of shipping directly from smart storage? No matter, where you are? we can ship your luggage and boxes anytime, anywhere! Get Cheapest Shipping.`
  },
  ['privacy']: {
    title: `Privacy Policy`,
    description: `Learn how ShipAndStorage protects your privacy with our privacy policy.`,
    keywords: `privacy, policy, data protection, personal information`
  },
  ['terms']: {
    title: `Terms and Conditions`,
    description: `Read and agree to ShipAndStorage's terms & conditions before using our services.`,
    keywords: `terms, conditions, agreement, legal`
  },
  ['prohibited_items']: {
    title: `Prohibited and Restricted Items`,
    description: `Learn about restricted goods like aerosols, alcohol, dangerous goods etc to ensure your shipments comply with regulations. Prohibited items are not allowed.`,
    keywords: `What Can't Ship, Restricted Items, Prohibited Items, Shipping Restrictions, Safe Shipping, ShipAndStorage, Shipping Compliance, Shipping Regulations, Storage Solutions, Shipping Solutions, Smart Shipping, Shipping Guidance, Storage Services, Shipping Services, Customer Support, Shipping Help, Safe Storage, Shipping Safety, Restricted Shipments, Prohibited Shipments, Safe Shipping Practices.`
  },
  ['shipping_tips']: {
    title: `Top Shipping Tips for Efficient and Safe Delivery`,
    description: `Optimize your shipping process with ShipAndStorage's expert tips. Learn about packaging, labeling, and cost-saving strategies to ensure efficient delivery.`,
    keywords: `Shipping Tips, Packaging Tips, Labeling Tips, Shipping Advice, Safe Shipping, Efficient Shipping, ShipAndStorage, Shipping Solutions, Storage Solutions, Smart Shipping Tips, Shipping Guides, Packing Advice, Shipping Services, Storage Services, Shipping Help, Storage Help, Customer Support, Shipping Recommendations, Safe Packing, Shipping Efficiency, Storage Tips, Shipping Assistance.`
  },
  ['general_faq']: {
    title: `Shipping & Storage Frequently Asked Questions`,
    description: `Find answers to your shipping and storage questions with ShipAndStorage. Comprehensive FAQ to help you with all your storage and shipping needs.`,
    keywords: `Shipping & Storage Questions, Shipping FAQ, Storage FAQ, Shipping Answers, Storage Answers, ShipAndStorage, Storage Solutions, Shipping Solutions, Common Questions, Shipping Tips, Storage Tips, Storage Services, Shipping Services, Customer Support, Storage Help, Shipping Help, Frequently Asked Questions, Storage Guidance, Shipping Guidance, Help Center, Smart Storage Solutions, Shipping & Storage Help.`
  },
  ['shipping_weight']: {
    title: `Shipping Weight and Size Guidelines`,
    description: `Understand shipping weight and size guidelines with ShipAndStorage. Ensure your items meet requirements for smooth delivery.`,
    keywords: `shipping weight, shipping size, shipping guidelines, ShipAndStorage, weight requirements, size requirements, shipping regulations, delivery guidelines, package weight, package size, shipping dimensions, weight limits, size limits, shipping instructions, shipping compliance, smooth delivery, shipping solutions, packaging requirements, shipping tips`
  },
  ['luggage_tracking']: {
    title: `Real-Time Luggage Tracking Services`,
    description: `Track your luggage in real-time with ShipAndStorage. Enjoy peace of mind with our reliable luggage tracking solutions.`,
    keywords: `luggage tracking, real-time tracking, luggage services, ShipAndStorage, tracking solutions, secure tracking, travel tracking, luggage monitoring, track luggage, real-time luggage updates, travel peace of mind, luggage security, tracking system, luggage safety, shipment tracking, travel solutions, reliable tracking`
  },
  ['luggage_drop_off_location']: {
    title: `Convenient Luggage Drop Off Locations`,
    description: `Find secure and convenient luggage drop-off locations with ShipAndStorage. Hassle-free storage solutions for travelers.`,
    keywords: `luggage drop off, secure luggage storage, convenient drop off, travel storage, ShipAndStorage, luggage services, storage locations, luggage solutions, easy drop off, luggage storage facilities, travel convenience, drop off points, secure storage, storage options, luggage safety, travel storage solutions, luggage services`
  },
  ['url_list']: {
    title: `Sitemap | Easy Access to All Pages and Services`,
    description: `Explore the comprehensive sitemap for ShipAndStorage.com, providing easy access to all our services, including shipping, storage solutions, and logistics support. Navigate through our pages to find the right solutions for your storage and shipping needs.`,
    keywords: `ShipAndStorage sitemap, shipping services, storage solutions, logistics support, page directory, shipping and storage sitemap, logistics sitemap, website navigation, sitemap for ShipAndStorage.com`
  },

  ['unauthorized']: {
    title: `401 unauthorized`,
    description: ``,
    keywords: ``
  },
  ['not_found']: {
    title: `Ops! 404 page not found`,
    description: ``,
    keywords: ``
  },
  ['server_error']: {
    title: `500 Internal Server Error`,
    description: ``,
    keywords: ``
  },

  // After Login Pages
  ['dashboard']: {
    title: `Dashboard`,
    description: `Smart storage, Ship and storage, Storage and shipping, Storage facilities in New York, Storage facilities in Delaware, Storage and shipping services, Affordable storage, Cheapest storage, International storage and shipping, Storage and shipping services USA, Storage ez, Ez storage, Ez storage near me, Easy storage, Easy storage solutions, Easy storage near me, My storage near me, Self storage, Self storage near me, Self storage units near me, self storage facilities near me`,
    keywords: `Store 1 Small box To 100 Large boxes Storage with Free Insurance up to $250 and 6 Months Free Storage. Get Smart Storage Services And ship anytime, anywhere!`
  },
  ['my_shipment']: {
    title: `My Shipment`
  },
  ['my_storage']: {
    title: `My Storage`
  },
  ['order_processing']: {
    title: `Order Processing`
  },
  ['custom_documents']: {
    title: `Custom Documents`
  }
})
